import Resource from "../OBJRep/Resource";
import { toDataURL } from "./Utils";

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
const RESOURCES_BASE_URL = process.env.REACT_APP_RESOURCES_BASE_URL;
//TODO:Tags for post must be handled in the backend.
export const postLogo = async (file, email, token) => {
  const fileName = `${email}_LOGO.${file.type.split("/")[1]}`;
  const newFile = new File([file], fileName, { type: file.type });
  const formData = new FormData();
  formData.append("files", newFile);
  formData.append("tags", `${email}_LOGO`);
  formData.append("category", "LOGO");
  const res = await fetch(`${RESOURCES_BASE_URL}/files`, {
    method: "POST",
    body: formData,
    headers: { Authorization: token },
  });
};

//TODO:Tags for post must be handled in the backend.
export const loadLogo = async (email, token) => {
  const url = `${RESOURCES_BASE_URL}/files/LOGO/${email}_LOGO`;

  const res = await fetch(url, { headers: { Authorization: token } });
  let logoLocation = "";
  let dataUrl = "";

  if (res.ok) {
    const json = await res.json();
    logoLocation = json.resourceImages[0].location + "/";
    dataUrl = await toDataURL(logoLocation, token);
  }
  return Resource(dataUrl, logoLocation, `${email}_logo`);
};

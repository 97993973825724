import React, { useEffect, useRef, useState } from "react";
import {
  BrandLogo,
  calcMoreUsedProductStyles,
  calcProductStylesOcurrence,
  Canvas,
  Contacts,
  Footer,
  GridProducts,
  Header,
} from "../Canvas/CanvasGeneralStyles";
import styled, { css } from "styled-components";
import ExpirationDate, {
  ExpirationDateStyle,
} from "../../../sharable/ExpirationDate";
import {
  CANVAS_TARGET_CLASSNAME,
  MAX_PRODUCTS_STORIES_FORMAT,
  STORIES_DIM,
} from "../EncarteConstants";
import Product from "../../../sharable/EncarteProduct";
import BrandAddress, { AddressStyle } from "../../../sharable/BrandAddress";
import {
  onLogoMouseDown,
  onLogoMouseMove,
  onLogoMouseUp,
} from "../SocialMediaCanvasViewer";
import BrandContacts from "../../../sharable/BrandContacts";
const StoriesCanvasContainer = styled(Canvas)``;

const StoriesHeader = styled(Header)``;

const StoriesGridProducts = styled(GridProducts)``;

const StoriesFooter = styled(Footer)`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  ${({ scale }) => css`
    ${ExpirationDateStyle} {
      width: 100%;
      margin: 0;
      height: min-content !important;
      text-align: center;
      font-size: ${20 * scale}px;
    }
  `}

  ${AddressStyle} {
    margin-top: 0;
    p {
      margin-top: 0;
    }
    .logo {
      ${({ scale }) => css`
        font-size: ${48 * scale}px !important;
      `}
    }
    width: 100%;
    border-radius: 0;
    border-radius: 10px;
    font-weight: bold;
  }
`;

export const BrandNamePhraseWrapper = styled.div`
  //border-right: 1px solid black;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ scale, brand, styles }) => css`
    padding: ${20 * scale}px;
    h2 {
      font-size: ${brand.name && brand.name.length > 20
        ? 30 * scale
        : 36 * scale}px;
      text-transform: capitalize;
      color: ${styles.priceTxtColor};
    }
    p {
      font-size: ${brand.name && brand.name.length > 20
        ? 24 * scale
        : 28 * scale}px;
      margin-top: ${10 * scale}px;
      font-weight: bold;
      color: ${styles.priceTxtColor};
    }
  `}
`;

const BrandNameAdressContactsWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  height: 100%;
`;

const AddressContactsWrapper = styled.div`
  display: flex;

  justify-content: space-around;
  flex-direction: column;
`;
export default function StoriesCanvas({
  styles,
  scale,
  brand,
  encarte,
  model,
  products,
  grid,
  isTargetCanvas,
}) {
  const [storiesGrid, setStoriesGrid] = useState();
  const [headerAndFooterStyles, setHeaderAndFooterStyles] = useState({});
  useEffect(() => {
    setHeaderAndFooterStyles(calcProductStylesOcurrence(products));
  }, [products]);

  const watchProductsAndGrid = useEffect(() => {
    if (grid) {
      setStoriesGrid(grid);
    } else {
      setStoriesGrid(
        calcStoriesGrid(
          products.length > MAX_PRODUCTS_STORIES_FORMAT
            ? MAX_PRODUCTS_STORIES_FORMAT
            : products.length
        )
      );
    }
  }, [products, grid]);

  return (
    <StoriesCanvasContainer
      scale={scale}
      styles={styles}
      model={model}
      dimensions={STORIES_DIM}
      className={isTargetCanvas && CANVAS_TARGET_CLASSNAME}
    >
      <StoriesHeader onMouseMove={onLogoMouseMove}>
        <BrandLogo
          logo={brand.logoURLTmp}
          scale={scale}
          logoSize={styles.logoSize}
          encarteStyles={styles}
          headerStyles={headerAndFooterStyles}
          onMouseDown={onLogoMouseDown}
          onMouseUp={onLogoMouseUp}
        />
      </StoriesHeader>

      {storiesGrid && (
        <StoriesGridProducts grid={storiesGrid} styles={styles} scale={scale}>
          {products.slice(0, MAX_PRODUCTS_STORIES_FORMAT).map((product) => (
            <Product product={product} scale={scale} styles={styles} />
          ))}
        </StoriesGridProducts>
      )}
      {headerAndFooterStyles && (
        <StoriesFooter model={model} scale={scale}>
          <ExpirationDate
            endDate={encarte.endDate}
            startDate={encarte.startDate}
            styles={headerAndFooterStyles}
            scale={scale}
          />
          <BrandNameAdressContactsWrapper>
            <BrandNamePhraseWrapper
              scale={scale}
              brand={brand}
              styles={headerAndFooterStyles}
            >
              <h2>{brand.name}</h2>
              <p>{brand.phrase}</p>
            </BrandNamePhraseWrapper>
            <AddressContactsWrapper>
              <BrandAddress
                brand={brand}
                styles={headerAndFooterStyles}
                scale={scale}
              />
              <BrandContacts
                brand={brand}
                scale={scale}
                styles={headerAndFooterStyles}
              />
            </AddressContactsWrapper>
          </BrandNameAdressContactsWrapper>
        </StoriesFooter>
      )}
    </StoriesCanvasContainer>
  );
}

const calcStoriesGrid = (productsSize) => {
  const MAX_COLUMNS = 5;
  const MAX_ROWS = 5;
  const grid = { columns: "", rows: "", highLight: undefined };
  const evenCount = productsSize % 2 === 0 ? productsSize : productsSize + 1;
  if (productsSize === 1) {
    grid.columns = 1;
    grid.rows = 1;
  } else if (evenCount === 4) {
    grid.columns = 2;
    grid.rows = 2;
  } else {
    for (let j = MAX_ROWS; j >= 1; j--) {
      for (let i = 1; i <= MAX_COLUMNS; i++) {
        if (i * j === evenCount && j > grid.rows) {
          grid.columns = i;
          grid.rows = j;
          break;
        }
      }
    }
  }

  grid.highLight = grid.columns * grid.rows != productsSize ? "row" : undefined;
  return grid;
};

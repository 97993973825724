import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import {
  FEED_INSTAGRAM,
  STORIES,
  CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME,
  CANVAS_VIWER_TARGET_CLASSNAME,
  PANFLETO,
  MAX_PRODUCTS_FEED_FORMAT,
  MAX_PRODUCTS_STORIES_FORMAT,
  MAX_PRODUCTS,
  CANVAS_TARGET_CLASSNAME,
} from "./EncarteConstants";

import Loading from "../../sharable/Loading";

import FeedCanvas from "./Canvas/FeedCanvas";
import StoriesCanvas from "./Canvas/StoriesCanvas";
import PanfletoCanvas from "./Canvas/PanfletoCanvas";
import { CanvasWrapper } from "./Canvas/CanvasGeneralStyles";
const Container = styled.div`
  border-top: 1px solid #d9d9d9;
  position: relative !important;
  padding: 2rem 4.2rem;
  display: flex;
  flex-direction: column;
  height: min-content;
  //overflow: hidden;
`;

const TimeOutAnimation = keyframes`
  to {
    width: 0%;
  }
`;

const PopUpTimeOut = styled.div`
  width: 100%;
  height: 5px;
  background-color: #d97706;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: ${TimeOutAnimation} 15s linear;
`;

const FormatSelector = styled.select`
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  text-transform: capitalize;
  cursor: pointer;
  &:focus-visible {
    outline-width: 0;
  }

  option {
    cursor: pointer;
  }
`;

const PopUp = styled.div`
  position: fixed;
  top: 10rem;
  right: 4.2rem;
  width: 25rem;
  border-radius: 5px;
  background-color: #171717;
  color: #fff;
  line-height: 1.3;
  font-size: 1.2rem;
  z-index: 10;
  box-shadow: 0px 10px 15px rgba(1, 1, 1, 0.2);

  button {
    all: unset;
    margin-top: 1rem;
    cursor: pointer;
    padding: 0.6rem 1.2rem;
    border: #d9d9d9 1px solid;
    align-self: end;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    &:hover {
      border-color: transparent;
      color: #d97706;
    }
  }

  .txt-button-wrapper {
    padding: 1.2rem;
  }
`;

const LOGO_DRAGABLE_CLASSNAME = "logo-dragable";

export function onLogoMouseDown(e) {
  const canvas = document.querySelector(`.${CANVAS_TARGET_CLASSNAME}`);
  canvas.addEventListener("mousemove", onLogoMouseMove);
  e.target.classList.add(LOGO_DRAGABLE_CLASSNAME);
}

export function onLogoMouseMove(e) {
  const logo = document.querySelector(`.${LOGO_DRAGABLE_CLASSNAME}`);
  if (!logo) return;
  logo.style.zIndex = 1000;
  logo.style.position = "absolute";
  logo.style.left = e.pageX - logo.offsetWidth / 2 + "px";
  logo.style.top = e.pageY - logo.offsetHeight + "px";
}

export function onLogoMouseUp(e) {
  e.target.classList.remove(LOGO_DRAGABLE_CLASSNAME);
  const canvas = document.querySelector(`.${CANVAS_TARGET_CLASSNAME}`);
  canvas.removeEventListener("mousemove", onLogoMouseMove);
}

export default function SocialMediaCanvasViewer({
  scale,
  maxProducts,
  currentModel,
  brand,
  encarteProducts,
  styles,
  updateEncarteStyles,
  updateEncarteProducts,
  encarte,
  grid,
  priceDesignList,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const watchMaxProducts = useEffect(() => {
    const format = styles.format;
    if (
      format === FEED_INSTAGRAM &&
      encarteProducts.length >= MAX_PRODUCTS_FEED_FORMAT
    ) {
      setShowPopUp(true);
    }
    if (
      format === STORIES &&
      encarteProducts.length >= MAX_PRODUCTS_STORIES_FORMAT
    ) {
      setShowPopUp(true);
    }
    if (format === PANFLETO && encarteProducts.length >= maxProducts) {
      setShowPopUp(true);
    }
    const timer = setTimeout(() => {
      setShowPopUp(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, [encarteProducts.length, styles.format]);

  return (
    <Container className={`flex align ${CANVAS_VIWER_TARGET_CLASSNAME}`}>
      {styles && brand && currentModel ? (
        <>
          {showPopUp ? (
            <PopUp className="flex direction-column">
              <div className="txt-button-wrapper flex direction-column">
                <p>
                  A quantidade máxima de produtos para o formato "
                  {styles.format}" é de{" "}
                  {styles.format == FEED_INSTAGRAM
                    ? MAX_PRODUCTS_FEED_FORMAT
                    : styles.format === STORIES
                    ? MAX_PRODUCTS_STORIES_FORMAT
                    : MAX_PRODUCTS}{" "}
                  produtos, passar disso irá comprometer a qualidade do encarte.
                  Crie outro encarte para continuar adicionando.
                </p>
                <button onClick={() => setShowPopUp(false)}>entendi</button>
              </div>
              <PopUpTimeOut />
            </PopUp>
          ) : (
            ""
          )}
          <FormatSelector
            onClick={(e) => {
              const s = { ...styles };
              s.format = e.target.value;
              updateEncarteStyles(s);
            }}
            defaultValue={styles.format}
          >
            <option value={FEED_INSTAGRAM}>{FEED_INSTAGRAM}</option>
            <option value={STORIES}>{STORIES}</option>
            <option value={PANFLETO}>{PANFLETO}</option>
          </FormatSelector>

          <CanvasWrapper className={CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME}>
            {styles.format === FEED_INSTAGRAM ? (
              <FeedCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
                grid={grid}
                isTargetCanvas={true}
                updateEncarteProducts={updateEncarteProducts}
                priceDesignList={priceDesignList}
              />
            ) : styles.format === STORIES ? (
              <StoriesCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
                grid={grid}
                isTargetCanvas={true}
                priceDesignList={priceDesignList}
              />
            ) : (
              <PanfletoCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
                grid={grid}
              />
            )}
          </CanvasWrapper>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

export function copy(src) {
  var dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(new Uint8Array(src));
  return dst;
}

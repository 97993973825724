import { IonIcon } from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import styled, { css } from "styled-components";
import ProductObj from "../../OBJRep/Product";
import ProductStyles from "../../OBJRep/ProductStyles";
import {
  copyProductPropertiesWithoutStyles,
  searchIntoCollection,
  toDataURL,
} from "../../services/Utils";
import Button from "../sharable/ActionButton";
import { Image } from "../sharable/EncarteProduct";
import { Input } from "../sharable/InputStyle";
import { ListProduct } from "../sharable/ListProduct";
import Loading from "../sharable/Loading";
import PopUp from "../sharable/PopUp";
import PremiumContentProtectiveLayer from "../sharable/PremiumContentProtectiveLayer";
import { ProductForm } from "../sharable/ProductForm";
import {
  MenuDescription,
  MenuTitle,
} from "../WorkSpace/ToolBarMenuViews/Toolbar";
import { DEFAULT_PRICE_PUBLIC_LOCATION } from "../WorkSpace/CanvasViewer/EncarteConstants";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 8.4rem);
  overflow-y: scroll;
  overflow-x: hidden;

  .query-input {
    width: 100%;
    margin-bottom: 3rem;
  }

  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      overflow-y: hidden;
      overflow-x: hidden;
      height: 100vh;
      main {
        filter: blur(3px);
      }
    `}

  .menu-title {
    margin-top: 3rem;
  }
`;

const List = styled.ul`
  gap: 3rem;
  width: 100%;
  position: relative;
  overflow: auto;

  .loading-message-wrapper {
    min-width: 100%;
    min-height: 10rem;
  }
`;

const ProductFormWrapper = styled.div`
  overflow: hidden;
  margin: 1.6rem 0;
  position: relative;

  .image-span {
    text-align: center;
  }
  .close-form-span {
    font-size: 1.2rem;
    border: 1px solid #fff;
    padding: 0.4rem 0.8rem;
    width: 40%;
    justify-content: space-around;
    margin-bottom: 1.2rem;
    font-weight: bold;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      background-color: #d97706;
      border-color: transparent;
    }
  }

  animation: $;
`;

const ProductButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  .product-count {
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 2rem;
  }
`;

const GRID_FULL_MESSAGE =
  "Sua grade de produtos está cheia! Para poder adicionar mais produtos escolha uma grade com uma quantidade maior de produtos.";
const GRID_FULL_MESSAGE_v2 = "Sua grade de produtos está cheia!";

const PRODUCT_ALREADY_EXISTS_MESSAGE =
  "Esse produto já foi inserido no encarte. Encartes não podem possuir itens duplicados";
const PRODUCT_NOT_DELETABLE_MESSAGE =
  "Esse produto não pode ser deletado pois está sendo utilizado em outros encartes!";
const PRODUCT_CREATED_SUCCESSFULLY_MESSAGE = "Produto criado com sucesso!";
const PRODUCT_UPDATED_SUCESSFULLY_MESSAGE = "Produto atualizado com sucesso!";
const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
export default function MyProductsView({
  encarteProducts,
  updateEncarteProducts,
  grid,
  userProducts,
  updateUserProducts,
  maxProducts,
  props,
}) {
  const { token, isAccountPremium } = useOutletContext();
  const [showUploadProductForm, setShowUploadProductForm] = useState(false);
  const [showUpdateProductForm, setShowUpdateProductForm] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [productNameInputSearch, setProductNameInputSearch] = useState("");
  const [toUploadProduct, setToUploadProduct] = useState();
  const [toUpdateProduct, setToUpdateProduct] = useState();
  const [searchImageResults, setSearchImageResults] = useState([]);
  const [encarteProductsCache, setEncarteProductsCache] =
    useState(encarteProducts);

  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpMessage, setPopUpmessage] = useState();
  const addProductToEncarte = async (product) => {
    console.log(product);
    const copy = [...encarteProductsCache];
    let productExists = false;
    let hasGridSpaceAvailable = false;
    let gridSpaceIndex = undefined;
    for (let i = 0; i < encarteProductsCache.length; i++) {
      if (encarteProductsCache[i].name === product.name) productExists = true;
      if (
        encarteProductsCache[i].name.toLowerCase().includes("nome do produto")
      ) {
        hasGridSpaceAvailable = true;
        gridSpaceIndex = i;
        break;
      }
    }
    if (productExists) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_ALREADY_EXISTS_MESSAGE);
      return;
    } else if (grid && !hasGridSpaceAvailable) {
      setPopUpmessage(GRID_FULL_MESSAGE);
      setShowPopUp(true);
      return;
    } else if (
      encarteProductsCache.length >= maxProducts &&
      !hasGridSpaceAvailable
    ) {
      setPopUpmessage(GRID_FULL_MESSAGE_v2);
      setShowPopUp(true);
    }
    if (hasGridSpaceAvailable) {
      product.id = gridSpaceIndex;
      copy[gridSpaceIndex] = copyProductPropertiesWithoutStyles(
        product,
        copy[gridSpaceIndex]
      );
    } else if (encarteProductsCache.length < maxProducts) {
      product.styles = ProductStyles();
      product.styles.priceStyleTmp = DEFAULT_PRICE_PUBLIC_LOCATION;
      copy.push(product);
    }
    updateEncarteProducts(copy);
    setEncarteProductsCache(copy);
  };
  const watchUserTyping = useEffect(() => {
    setIsUserTyping(true);
    const timeout = setTimeout(() => {
      if (!productNameInputSearch) return;

      setIsUserTyping(false);
    }, 1200);
    return () => clearTimeout(timeout);
  }, [productNameInputSearch]);

  const createNewProduct = async () => {
    if (!toUploadProduct.name) return;
    const res = await fetch(`${SERVER_BASE_URL}products`, {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(toUploadProduct),
    });
    if (res.ok) {
      const userProductsCopy = [...userProducts];
      userProductsCopy.push(toUploadProduct);
      setShowUploadProductForm(false);
      updateUserProducts(userProductsCopy);
      setToUploadProduct(ProductObj());
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_CREATED_SUCCESSFULLY_MESSAGE);
    }
  };
  const handleImageClick = (image, object, callback) => {
    if (!image) return;
    const copy = { ...object };
    copy.imageURL = image.url;
    copy.displayUrl = image.displayUrl;
    callback(copy);
  };
  const handleFormUpdate = (prop, value, object, callback) => {
    const copy = { ...object };
    copy[prop] = value;

    callback(copy);
  };
  const deleteProduct = async (product) => {
    const res = await fetch(`${SERVER_BASE_URL}products/${product.id}`, {
      headers: { Authorization: token },
      method: "DELETE",
      body: JSON.stringify(toUploadProduct),
    });
    if (res.ok) {
      const nArr = userProducts.filter((p) => p.id != product.id);
      updateUserProducts(nArr);
      alert("Produto deletado com êxito");
    } else if (res.status === 500) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_NOT_DELETABLE_MESSAGE);
    }
  };

  const updateProduct = async () => {
    const res = await fetch(`${SERVER_BASE_URL}products`, {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "PUT",
      body: JSON.stringify(toUpdateProduct),
    });
    if (res.ok) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_UPDATED_SUCESSFULLY_MESSAGE);

      /*UPDATING USER PRODUCT LIST */
      updateProductOnUserList();
      /*UPDATING PRODUCT ON ENCARTE */
      updateProductOnEncarte();
    } else {
      setShowPopUp(true);
      setPopUpmessage("Não foi possível atualizar o produto :(");
    }
  };

  const updateProductOnUserList = () => {
    const nArr = [];
    userProducts.slice().forEach((product) => {
      if (product.id === toUpdateProduct.id) {
        product = toUpdateProduct;
      }
      nArr.push(product);
    });
    updateUserProducts(nArr);
  };
  const updateProductOnEncarte = () => {
    const arr = [];
    encarteProducts.forEach((product) => {
      if (product.id === toUpdateProduct.id) {
        product = copyProductPropertiesWithoutStyles(toUpdateProduct, product);
      }
      arr.push(product);
    });
    updateEncarteProducts(arr);
  };
  return (
    <Container
      isAccountPremium={isAccountPremium}
      className="menu-view-container"
    >
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main className="main-view">
        <MenuDescription>
          Você pode criar encartes adicionando produtos já cadastrados aqui!
        </MenuDescription>
        <PopUp
          setShowPopUp={setShowPopUp}
          text={popUpMessage}
          showPopUp={showPopUp}
          buttonText={"Ok"}
          position="fixed"
          top="0rem"
          right="2rem"
        />
        {!showUploadProductForm && !showUpdateProductForm && (
          <Button
            $NoBgColor
            $fullWidth
            text="Registrar novo produto +"
            color="#fff"
            width="calc(100% - 3px)"
            action={() => {
              setToUploadProduct(ProductObj());
              setShowUploadProductForm((t) => true);
            }}
            size="1.4"
          />
        )}

        {showUploadProductForm && (
          <>
            <Button
              $NoBgColor
              $fullWidth
              text="Salvar produto"
              color="#fff"
              width="calc(100% - 3px)"
              action={() => {
                setToUploadProduct(ProductObj());
                createNewProduct();
              }}
              size="1.4"
            />
            <ProductFormWrapper className="wrapper flex direction-column">
              <span
                className="close-form-span flex align"
                onClick={() => setShowUploadProductForm(false)}
              >
                Fechar formulário <IonIcon icon={closeSharp} />
              </span>
              {toUploadProduct && toUploadProduct.imageURL != "" ? (
                <Image
                  url={toUploadProduct.displayUrl}
                  height="9rem"
                  backgroundSize="contain"
                  className="product-image"
                />
              ) : (
                <span className="image-span">
                  A imagem do produto aparecerá aqui
                </span>
              )}

              <ProductForm
                prod={toUploadProduct}
                currentProductImages={searchImageResults}
                handleCurrentProductImageUpdate={(image) => {
                  handleImageClick(image, toUploadProduct, setToUploadProduct);
                }}
                productIndex={0}
                currentProductId={0}
                currentProductName={toUploadProduct.name}
                downloadingProductImage={false}
                setCurrentProductId={() => {}}
                setCurrentProductImages={setSearchImageResults}
                setCurrentProductName={() => {}}
                setNoImageFound={() => {}}
                props={props}
                updateProductInfo={(id, field, value) => {
                  handleFormUpdate(
                    field,
                    value,
                    toUploadProduct,
                    setToUploadProduct
                  );
                }}
                showAdvancedSettingsForCurrentProduct={false}
                wasNoImageFound={false}
              />
            </ProductFormWrapper>
          </>
        )}
        {showUpdateProductForm && (
          <>
            <Button
              $NoBgColor
              $fullWidth
              text="Atualizar produto"
              color="#fff"
              width="calc(100% - 3px)"
              action={async () => {
                await updateProduct();
                setShowUpdateProductForm(false);

                setToUpdateProduct(undefined);
              }}
              size="1.4"
            />
            <ProductFormWrapper className="wrapper flex direction-column">
              <span
                className="close-form-span flex align"
                onClick={() => setShowUpdateProductForm(false)}
              >
                Fechar formulário <IonIcon icon={closeSharp} />
              </span>
              <div className="product-image-wrapper flex justify align">
                {toUpdateProduct && toUpdateProduct.imageURL != "" ? (
                  <Image
                    url={toUpdateProduct.displayUrl}
                    height="9rem"
                    backgroundSize="contain"
                    className="product-image"
                  />
                ) : (
                  "A imagem do produto aparecerá aqui"
                )}
              </div>
              <ProductForm
                prod={toUpdateProduct}
                currentProductImages={searchImageResults}
                handleCurrentProductImageUpdate={(image) => {
                  handleImageClick(image, toUpdateProduct, setToUploadProduct);
                }}
                productIndex={0}
                currentProductId={0}
                currentProductName={toUpdateProduct.name}
                downloadingProductImage={false}
                setCurrentProductId={() => {}}
                setCurrentProductImages={setSearchImageResults}
                setCurrentProductName={() => {}}
                setNoImageFound={() => {}}
                props={props}
                updateProductInfo={(id, field, value) => {
                  handleFormUpdate(
                    field,
                    value,
                    toUpdateProduct,
                    setToUpdateProduct
                  );
                }}
                showAdvancedSettingsForCurrentProduct={false}
                wasNoImageFound={false}
              />
            </ProductFormWrapper>
          </>
        )}
        <MenuTitle className="menu-title">Meus produtos</MenuTitle>
        <Input
          className="query-input"
          type="text"
          placeholder="Digite aqui para buscar um produto..."
          onChange={(e) => {
            setProductNameInputSearch(e.target.value);
          }}
        />

        <List className="flex direction-column justify">
          {isUserTyping && productNameInputSearch ? (
            <div className="loading-message-wrapper">
              <Loading
                $NoBg
                txt={`Buscando produto ${productNameInputSearch}...`}
                color="#fff"
              />
            </div>
          ) : productNameInputSearch != "" && !isUserTyping ? (
            searchIntoCollection(
              productNameInputSearch,
              "name",
              userProducts
            ).map((product) => (
              <>
                <ListProduct
                  product={product}
                  action={(product) => addProductToEncarte(product)}
                />
                <div className="flex min-gap space-between">
                  <Button
                    text={"Editar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => {
                      setShowUpdateProductForm(true);
                      setToUpdateProduct(product);
                      const container = document.querySelector(".main-view");
                      container.scrollIntoView({ behavior: "smooth" });
                    }}
                    size={"1.2"}
                  />
                  <Button
                    text={"Deletar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => deleteProduct(product)}
                    size={"1.2"}
                  />
                </div>
              </>
            ))
          ) : userProducts ? (
            userProducts.map((product, i) => (
              <ProductButtonWrapper>
                <div>
                  <span className="product-count">Produto {i + 1}.</span>
                  <ListProduct
                    product={product}
                    action={(product) => addProductToEncarte(product)}
                  />
                </div>
                <div className="flex min-gap space-between">
                  <Button
                    text={"Editar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => {
                      setShowUpdateProductForm(true);
                      console.log(product);
                      setToUpdateProduct(product);
                      const container = document.querySelector(".main-view");
                      container.scrollIntoView({ behavior: "smooth" });
                    }}
                    size={"1.2"}
                  />
                  <Button
                    text={"Deletar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => deleteProduct(product)}
                    size={"1.2"}
                  />
                </div>
              </ProductButtonWrapper>
            ))
          ) : (
            <Loading $NoBg color="#fff" txt="Carregando seus produtos..." />
          )}
        </List>
      </main>
    </Container>
  );
}

export const loadUserProducts = async (token) => {
  const res = await fetch(`${SERVER_BASE_URL}products`, {
    headers: {
      Authorization: token,
    },
  });
  const json = await res.json();

  const images = await Promise.all(
    json.map((product) => {
      return toDataURL(product.imageURL, token);
    })
  );
  const pCopy = [...json];
  images.forEach((image, i) => (pCopy[i].displayUrl = image));
  return pCopy;
};

import ProductObj from "../../../OBJRep/Product";
import ProductStyles from "../../../OBJRep/ProductStyles";
import {
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../CanvasViewer/EncarteConstants.js";

/* THIS FILE IS RESPONSIBLE FOR STORING GRADE DE PRODUTOS OPTIONS

EACH GRADE IS MADE USING function: Products();
KEY POINTS TO GENERATE A NEW GRID:

-- HOW MANY PRODUCTS GOES INTO THE GRID;
-- HOW MANY ROWS AND COLUMNS THE GRID WILL HAVE;
-- FOR PRODUCTS THAT ARE TO BE HIGHLIGHTED IN ENCARTE BY TAKING MORE THAN ONE ROW AND COLUMN, 
   WE CHOOSE THE INDEX OF THE PRODUCT, THEN SET IT'S GRID-COLUMN AND GRID-ROW PROPERTY.
   THOSE PROPERTIES ARE PASSED TO function: Products(); IN AN ARRAY OF arrPositions[], arrColumns[],
   arrRows[]. WE CAN STILL PASS PRODUCT LAYOUT TOO AS AN ARRAY arrLayouts[];
*/

const FEED_GRID_3_PRODUCTS_1 = {
  products: products(
    3,
    [0, 1, 2],
    ["1/2", "2/3", "3/4"],
    ["1/2", "1/2", "1/2"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(3, 1),
};
const FEED_GRID_3_PRODUCTS_2 = {
  products: products(
    3,
    [0, 1, 2],
    ["1/3", "1/2", "2/3"],
    ["1/2", "2/3", "2/3"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(2, 2),
};
const FEED_GRID_4_PRODUCTS_1 = {
  products: products(4, [0, 3], ["1/2", "3/4"], ["1/3", "1/3"]),
  grid: Grid(3, 2),
};
const FEED_GRID_4_PRODUCTS_2 = {
  products: products(
    4,
    [0, 3],
    ["1/2", "2/4"],
    ["1/3", "1/2"],
    [NAME_ABOVE_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
  grid: Grid(3, 2),
};

const FEED_GRID_5_PRODUCTS_1 = {
  products: products(
    5,
    [0, 2, 3],
    ["1/3", "3/5", "2/4"],
    ["1/2", "1/2", "2/3"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(4, 2),
};
const FEED_GRID_5_PRODUCTS_2 = {
  products: products(
    5,
    [0, 4, 3],
    ["1/2", "4/5", "2/4"],
    ["1/3", "1/3", "2/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(4, 2),
};
const FEED_GRID_6_PRODUCTS_1 = {
  grid: Grid(4, 2),
  products: products(
    6,
    [0, 5],
    ["1/3", "1/3"],
    ["1/2", "2/3"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};
const FEED_GRID_6_PRODUCTS_2 = {
  grid: Grid(4, 2),
  products: products(6, [0, 5], ["1/2", "4/5"], ["1/3", "1/3"], undefined),
};

const FEED_GRID_7_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    7,
    [0, 5],
    ["1/2", "3/4"],
    ["1/3", "1/3"],
    [NAME_ABOVE_PRODUCT_LAYOUT, NAME_ABOVE_PRODUCT_LAYOUT]
  ),
};

const FEED_GRID_7_PRODUCTS_2 = {
  grid: Grid(5, 2),
  products: products(
    7,
    [0, 1],
    ["1/4", "4/6"],
    ["1/2", "1/2"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_5_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    5,
    [0, 1],
    ["1/4", "1/4"],
    ["1/2", "2/3"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_5_PRODUCTS_2 = {
  grid: Grid(3, 3),
  products: products(
    5,
    [0, 1],
    ["1/3", "1/3"],
    ["1/3", "3/4"],
    [NAME_ABOVE_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_6_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    6,
    [0, 1, 2],
    ["1/2", "2/3", "3/4"],
    ["1/3", "1/3", "1/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_6_PRODUCTS_2 = {
  grid: Grid(4, 3),
  products: products(
    6,
    [0, 1],
    ["1/3", "3/5"],
    ["1/3", "1/3"],
    [NAME_ABOVE_PRODUCT_LAYOUT, NAME_ABOVE_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_7_PRODUCTS_1 = {
  grid: Grid(4, 3),
  products: products(
    7,
    [0, 1, 2, 3, 5],
    ["1/3", "3/6", "1/3", "3/6", "2/4"],
    ["1/2", "1/2", "2/3", "2/3", "3/4"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
};
const STORIES_GRID_7_PRODUCTS_2 = {
  grid: Grid(4, 3),
  products: products(
    7,
    [0, 1, 2, 3, 4, 5, 6],
    ["1/2", "2/4", "4/5", "", "", "", ""],
    ["1/3", "1/3", "1/3", "", "", "", ""],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_8_PRODUCTS_1 = {
  grid: Grid(4, 3),
  products: products(
    8,
    [0, 1],
    ["1/3", "3/5", "1/3"],
    ["1/2", "1/3", "2/3"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_8_PRODUCTS_2 = {
  grid: Grid(6, 3),
  products: products(
    8,
    [0, 1],
    ["1/4", "4/7"],
    ["1/3", "1/3"],
    [
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_10_PRODUCTS_1 = {
  grid: Grid(4, 4),
  products: products(
    10,
    [0, 1],
    ["1/3", "3/5"],
    ["1/3", "1/3"],
    [
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_10_PRODUCTS_2 = {
  grid: Grid(5, 4),
  products: products(
    10,
    [0, 1],
    ["1/4", "1/4"],
    ["1/3", "3/6"],
    [
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_12_PRODUCTS_1 = {
  grid: Grid(4, 3),
  products: products(12, [], [], [], []),
};

const STORIES_GRID_12_PRODUCTS_2 = {
  grid: Grid(5, 4),
  products: products(
    12,
    [0, 1],
    ["1/4", "4/6"],
    ["1/3", "3/5"],
    [NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT]
  ),
};

const PANFLETO_GRID_5_PRODUCTS_1 = {
  grid: Grid(6, 2),
  products: products(
    5,
    [0, 1, 2, 3, 4],
    ["1/4", "4/7", "1/3", "3/5", "5/7"],
    ["1/2", "1/2", "2/3", "2/3", "2/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
    ],
    "60"
  ),
};

const PANFLETO_GRID_5_PRODUCTS_2 = {
  grid: Grid(4, 4),
  products: products(
    5,
    [0, 1, 2, 3, 4],
    ["2/4", "1/2", "1/2", "4/5", "4/5"],
    ["1/5", "1/3", "3/5", "1/3", "3/5"],
    [NAME_ABOVE_PRODUCT_LAYOUT],
    "60"
  ),
};

const PANFLETO_GRID_6_PRODUCTS_1 = {
  grid: Grid(3, 2),
  products: products(
    6,
    [0, 1, 2, 3, 4, 5],
    ["1/2", "2/3", "3/4", "1/2", "2/3", "3/4"],
    ["1/2", "1/2", "1/2", "2/3", "2/3", "2/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
    ],
    "60"
  ),
};

const PANFLETO_GRID_6_PRODUCTS_2 = {
  grid: Grid(4, 2),
  products: products(
    6,
    [0, 1],
    ["1/3", "3/5"],
    ["2/3", "2/3"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ],
    "60"
  ),
};

const PANFLETO_GRID_9_PRODUCTS_1 = {
  grid: Grid(4, 3),
  products: products(
    9,
    [0, 1, 2],
    ["2/4", "2/4", "2/4"],
    ["1/2", "2/3", "3/4"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ],
    "46"
  ),
};
const PANFLETO_GRID_9_PRODUCTS_2 = {
  grid: Grid(6, 3),
  products: products(
    9,
    [0, 1, 2],
    ["1/3", "3/5", "5/7"],
    ["1/3", "1/3", "1/3"],
    [],
    "38"
  ),
};

const PANFLETO_GRID_10_PRODUCTS_1 = {
  grid: Grid(7, 6),
  products: products(
    10,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    ["1/4", "1/4", "4/6", "6/8", "4/6", "4/6", "4/6", "6/8", "6/8", "6/8"],
    ["1/4", "4/7", "1/4", "1/4", "4/5", "5/6", "6/7", "4/5", "5/6", "6/7"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      NAME_BENEATH_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ],
    "34"
  ),
};
const PANFLETO_GRID_10_PRODUCTS_2 = {
  grid: Grid(4, 4),
  products: products(
    10,
    [0, 1],
    ["1/3", "3/5"],
    ["1/3", "1/3"],
    [
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
      NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
    ],
    "38"
  ),
};

const PANFLETO_GRID_11_PRODUCTS_1 = {
  grid: Grid(6, 6),
  products: products(
    11,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [
      "1/4",
      "1/4",
      "4/5",
      "5/6",
      "6/7",
      "4/5",
      "5/6",
      "6/7",
      "4/5",
      "5/6",
      "6/7",
    ],
    [
      "1/4",
      "4/7",
      "1/3",
      "1/3",
      "1/3",
      "3/5",
      "3/5",
      "3/5",
      "5/7",
      "5/7",
      "5/7",
    ],
    [NAME_ABOVE_PRODUCT_LAYOUT, NAME_ABOVE_PRODUCT_LAYOUT],
    "38"
  ),
};

const PANFLETO_GRID_11_PRODUCTS_2 = {
  grid: Grid(12, 4),
  products: products(
    11,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [
      "1/5",
      "5/9",
      "9/13",
      "1/4",
      "4/7",
      "7/10",
      "10/13",
      "1/4",
      "4/7",
      "7/10",
      "10/13",
    ],
    [
      "1/3",
      "1/3",
      "1/3",
      "3/4",
      "3/4",
      "3/4",
      "3/4",
      "4/5",
      "4/5",
      "4/5",
      "4/5",
    ],
    [],
    "40"
  ),
};

const PANFLETO_GRID_12_PRODUCTS_1 = {
  grid: Grid(15, 15),
  products: products(
    12,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [
      "1/7",
      "1/7",
      "1/7",
      "7/10",
      "10/13",
      "13/16",
      "7/10",
      "10/13",
      "13/16",
      "7/10",
      "10/13",
      "13/16",
    ],
    [
      "1/6",
      "6/11",
      "11/16",
      "1/6",
      "1/6",
      "1/6",
      "6/11",
      "6/11",
      "6/11",
      "11/16",
      "11/16",
      "11/16",
    ],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ],
    "40"
  ),
};
const PANFLETO_GRID_12_PRODUCTS_2 = {
  grid: Grid(12, 4),
  products: products(
    11,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [
      "1/5",
      "5/9",
      "9/13",
      "1/4",
      "4/7",
      "7/10",
      "10/13",
      "1/4",
      "4/7",
      "7/10",
      "10/13",
    ],
    [
      "1/3",
      "1/3",
      "1/3",
      "3/4",
      "3/4",
      "3/4",
      "3/4",
      "4/5",
      "4/5",
      "4/5",
      "4/5",
    ],
    [],
    "40"
  ),
};

const PANFLETO_GRID_13_PRODUCTS_1 = {
  grid: Grid(4, 4),
  products: products(
    13,
    [0],
    ["2/4"],
    ["2/4"],
    [NAME_ABOVE_PRODUCT_LAYOUT],
    "36"
  ),
};
const PANFLETO_GRID_13_PRODUCTS_2 = {
  grid: Grid(7, 6),
  products: products(
    13,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    [
      "1/3",
      "3/5",
      "1/3",
      "3/5",
      "5/6",
      "6/7",
      "7/8",
      "5/6",
      "6/7",
      "7/8",
      "5/6",
      "6/7",
      "7/8",
    ],
    [
      "1/4",
      "1/4",
      "4/8",
      "4/8",
      "1/3",
      "1/3",
      "1/3",
      "3/5",
      "3/5",
      "3/5",
      "5/7",
      "5/7",
      "5/7",
    ],
    [],
    "40"
  ),
};

const PANFLETO_GRID_14_PRODUCTS_1 = {
  grid: Grid(20, 5),
  products: products(
    14,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    [
      "1/6",
      "6/11",
      "11/16",
      "16/21",
      "1/5",
      "5/9",
      "9/13",
      "13/17",
      "17/21",
      "1/5",
      "5/9",
      "9/13",
      "13/17",
      "17/21",
    ],
    [
      "1/4",
      "1/4",
      "1/4",
      "1/4",
      "4/5",
      "4/5",
      "4/5",
      "4/5",
      "4/5",
      "5/6",
      "5/6",
      "5/6",
      "5/6",
      "5/6",
    ],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ],
    "34"
  ),
};
const PANFLETO_GRID_14_PRODUCTS_2 = {
  grid: Grid(10, 12),
  products: products(
    14,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    [
      "1/6",
      "6/11",
      "1/6",
      "6/11",
      "1/6",
      "6/11",
      "11/12",
      "12/13",
      "11/12",
      "12/13",
      "11/12",
      "12/13",
      "11/12",
      "12/13",
    ],
    [
      "1/5",
      "1/5",
      "5/9",
      "5/9",
      "9/13",
      "9/13",
      "1/4",
      "1/4",
      "4/7",
      "4/7",
      "7/10",
      "7/10",
      "10/13",
      "10/13",
    ],
    [],
    "40"
  ),
};
/* THOSE ARRAYS WITH EACH FORMAT GRADE OPTIONS ARE EXPORTED AND USED IN GridMenuView.jsx */

export const FEED_GRIDS = [
  {
    title: "Grade com 3 produtos",
    gridsArr: [FEED_GRID_3_PRODUCTS_1, FEED_GRID_3_PRODUCTS_2],
  },
  {
    title: "Grade com 4 produtos",
    gridsArr: [FEED_GRID_4_PRODUCTS_1, FEED_GRID_4_PRODUCTS_2],
  },
  {
    title: "Grade com 5 produtos",
    gridsArr: [FEED_GRID_5_PRODUCTS_1, FEED_GRID_5_PRODUCTS_2],
  },
  {
    title: "Grade com 6 produtos",
    gridsArr: [FEED_GRID_6_PRODUCTS_1, FEED_GRID_6_PRODUCTS_2],
  },
  {
    title: "Grade com 7 produtos",
    gridsArr: [FEED_GRID_7_PRODUCTS_1, FEED_GRID_7_PRODUCTS_2],
  },
];

export const STORIES_GRIDS = [
  {
    title: "Grade com 5 produtos",
    gridsArr: [STORIES_GRID_5_PRODUCTS_1, STORIES_GRID_5_PRODUCTS_2],
  },
  {
    title: "Grade com 6 produtos",
    gridsArr: [STORIES_GRID_6_PRODUCTS_1, STORIES_GRID_6_PRODUCTS_2],
  },
  {
    title: "Grade com 7 produtos",
    gridsArr: [STORIES_GRID_7_PRODUCTS_1, STORIES_GRID_7_PRODUCTS_2],
  },
  {
    title: "Grade com 8 produtos",
    gridsArr: [STORIES_GRID_8_PRODUCTS_1, STORIES_GRID_8_PRODUCTS_2],
  },
  {
    title: "Grade com 10 produtos",
    gridsArr: [STORIES_GRID_10_PRODUCTS_1, STORIES_GRID_10_PRODUCTS_2],
  },
  {
    title: "Grade com 12 produtos",
    gridsArr: [STORIES_GRID_12_PRODUCTS_1, STORIES_GRID_12_PRODUCTS_2],
  },
];

export const PANFLETO_GRID = [
  {
    title: "Grade com 5 produtos",
    gridsArr: [PANFLETO_GRID_5_PRODUCTS_1, PANFLETO_GRID_5_PRODUCTS_2],
  },

  {
    title: "Grade com 6 produtos",
    gridsArr: [PANFLETO_GRID_6_PRODUCTS_1, PANFLETO_GRID_6_PRODUCTS_2],
  },
  {
    title: "Grade com 9 produtos",
    gridsArr: [PANFLETO_GRID_9_PRODUCTS_1, PANFLETO_GRID_9_PRODUCTS_2],
  },

  {
    title: "Grade com 10 produtos",
    gridsArr: [PANFLETO_GRID_10_PRODUCTS_1, PANFLETO_GRID_10_PRODUCTS_2],
  },

  {
    title: "Grade com 11 produtos",
    gridsArr: [PANFLETO_GRID_11_PRODUCTS_1, PANFLETO_GRID_11_PRODUCTS_2],
  },

  {
    title: "Grade com 12 produtos",
    gridsArr: [PANFLETO_GRID_12_PRODUCTS_1, PANFLETO_GRID_12_PRODUCTS_2],
  },

  {
    title: "Grade com 13 produtos",
    gridsArr: [PANFLETO_GRID_13_PRODUCTS_1, PANFLETO_GRID_13_PRODUCTS_2],
  },
  {
    title: "Grade com 14 produtos",
    gridsArr: [PANFLETO_GRID_14_PRODUCTS_1, PANFLETO_GRID_14_PRODUCTS_2],
  },
];

export function Grid(columns, rows, autoGrid = false) {
  return {
    columns: columns,
    rows: rows,
    autoGrid: autoGrid,
  };
}

/* THIS FUNCTION DO 3 THINGS:
  -- CREATES A NEW PRODUCT OBJECT
  -- UPDATE PRODUCT STYLES WITH GRID-COLUMN, GRID-ROW AND PRODUCT LAYOUT
     PROPERTIES
  -- RETURN AN ARRAY OF PRODUCTS
*/

function products(
  quantity,
  arrPositions,
  arrColumns,
  arrRows,
  arrLayouts,
  fontSize = "32"
) {
  const products = [];
  for (let i = 0; i < quantity; i++) {
    const p = ProductObj(i);
    p.styles = ProductStyles();
    if (fontSize) p.styles.fontSize = fontSize;
    products.push(p);
  }
  if (arrPositions) {
    for (let i = 0; i <= arrPositions.length - 1; i++) {
      if (arrColumns)
        products[arrPositions[i]].styles.gridColumn = arrColumns[i];
      if (arrRows) {
        products[arrPositions[i]].styles.gridRow = arrRows[i];
      }
      if (arrLayouts) {
        products[arrPositions[i]].styles.productLayout = arrLayouts[i];
      }
    }
  }
  return products;
}

import styled from "styled-components";
const VERSION = process.env.REACT_APP_VERSION;
const Container = styled.span`
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 1.2rem;
  padding: 0 1rem;
`;

export default function AppVersion() {
  return <Container>Versão {VERSION}</Container>;
}

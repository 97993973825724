export const ProductAdvancedFields = (
  promotionType = "SIMPLE_PROMOTION",
  takeX = 2,
  takeY,
  normalSellingPrice = "0,00",
  promotionPrice
) => {
  return {
    promotionType: promotionType,
    takeX: takeX,
    takeY: takeY,
    normalSellingPrice: normalSellingPrice,
    promotionPrice: promotionPrice,
  };
};

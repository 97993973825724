import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "./ActionButton";
import { useNavigate } from "react-router";
import { IonIcon } from "@ionic/react";
import { lockClosed } from "ionicons/icons";

const Container = styled.div`
  position: absolute;
  width: calc(100% + 4rem);
  height: calc(100% + 4rem);
  background-color: rgba(1, 1, 1, 0.7);
  text-align: center;
  padding: 3rem;
  z-index: 10;
  margin: -2rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
`;

export default function PremiumContentProtectiveLayer({ className }) {
  const navigate = useNavigate();

  return (
    <Container className={className}>
      <Paragraph>
        Exclusivo para usuários premium <IonIcon icon={lockClosed} />
      </Paragraph>
      <Button
        action={() => navigate("/metodo-pagamento?lookup_key=monthly_premium")}
        text="Mudar de plano e desbloquear"
        size="1.4"
      />
    </Container>
  );
}

import styled, { css, keyframes } from "styled-components";
import { Selector } from "./PickerAndSelector";
import { devices } from "../../Devices";
import { IonIcon } from "@ionic/react";
import { fadeInAnimation } from "./Animations";
import Loading from "./Loading";
import Button from "./ActionButton";
import { trashOutline, settingsOutline } from "ionicons/icons";
import { UNITYS } from "../WorkSpace/CanvasViewer/EncarteConstants";
import { searchProductImages } from "../../services/ProductImageService";
import { Image } from "../sharable/EncarteProduct";
import UploadButton from "./UploadButton";
import { useOutletContext } from "react-router";
const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export const BoxImage = styled.div`
  span {
    color: #fff !important;
  }
  position: relative;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #fff;
  color: black;
  align-items: center;
  justify-content: center;
  height: min-content;
  width: 100%;
  .loading-currentProductImages {
    animation: ${rotating} 1s linear infinite;
    color: #d97706;
    grid-column: 1/-1;
    display: inline-block;
  }
  .button {
    grid-column: 1/-1;
  }
  ${({ imagesCount }) => {
    const gridColumns = imagesCount < 4 ? imagesCount : 4;
    return css`
      grid-template-columns: repeat(${gridColumns}, 1fr);
    `;
  }}
`;

const ProductInput = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  padding: 1.4rem 0;
  column-gap: 1.4rem;
  row-gap: 1.4rem;

  .buttons {
    grid-column: 1/3;
    width: calc(100% - 3px);
  }
  & .name {
    grid-column: 1/3;
    align-self: end;
  }

  align-items: end;

  .icon {
    font-size: 1.8rem;
    transition: all 300ms easy-in-out;
  }

  .icon-wrapper {
    width: min-content;
    height: min-content;
    position: relative;
    cursor: pointer;
    gap: 0.6rem;
    padding: 0.2rem 0.4rem;

    span {
      font-size: 1.4rem;
      text-transform: capitalize;
    }
  }

  .icon-wrapper:hover {
    color: #dc2626;
  }
  .icon-wrapper:before {
    right: 0;
    top: 0;
    position: absolute;
    font-size: 1.2rem;
    transform: translate(120%, -20%);
    color: #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 0px;
    opacity: 1;
  }

  .icon-wrapper:hover:before {
    opacity: 1;
  }
  .icon:hover {
  }

  .not-deleteable-message {
    grid-column: 1/3;
  }
`;

const Input = styled.input`
  padding: 1.2rem 0 1.2rem 1rem;
  text-transform: capitalize;
  height: 4.2rem;
  accent-color: #d97706;

  input,
  select {
    color: #ffffff !important;
    -webkit-text-fill-color: #ffffff !important;
    -webkit-background-clip: text !important;
  }
`;

const LabelInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  &.checkbox {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
`;

const NoImagesMessage = styled.p`
  font-size: 1.2rem;
  text-align: center;
  grid-column: 1/3;
`;

const AdvancedOptionsButton = styled.div`
  cursor: pointer;
  transition: ease-in-out 200ms;
  display: flex;
  width: 100%;
  grid-column: 1/3;
  span {
    font-size: 1.4rem;
    display: inline-block;
    text-align: center;
  }

  &&:hover {
    color: #d97706;
  }

  .icon {
    font-size: 1.8rem;
  }
`;

const FloatWindowStyles = css`
  width: 32%;
  padding: 2.4rem;
  height: 50rem;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  bottom: 0%;
  left: 1%;
  box-shadow: 0px 0px 50px 2px rgba(1, 1, 1, 0.1);
  border-radius: 10px;
  color: #444444;
  animation: ${fadeInAnimation} 0.2s linear;
  .icon-wrapper {
    align-self: end;
    cursor: pointer;
    span,
    .icon {
      transition: all ease-in-out 200ms;
    }
  }
  .icon {
    align-self: end;
    color: black;
    font-size: 1.8rem;
  }

  .icon-wrapper:hover {
    .icon,
    span {
      color: #d97706;
    }
  }

  span {
    font-size: 1.4rem;
    text-align: center;
  }

  .edit-product-span {
    ont-size: 1.6rem;
    margin-top: 3.2rem;
  }
  //Promoções header
  h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 3.6rem;
    font-weight: 600;
  }

  p {
    font-size: 1.2rem;
  }

  .promo-fields-list {
    margin-top: 2.4rem;
    gap: 1.4rem;
    width: 70%;
  }

  input {
    border: none;
    padding: 1.2rem 0 1.2rem 1rem;
    text-transform: capitalize;
    height: 4.2rem;
    background-color: #f5f5f5;
  }

  @media ${devices.tablet} {
    width: 90%;
    max-height: 100vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .invalid-input {
    background-color: #ff7f7f !important;
  }
`;

export function ProductForm({
  wasNoImageFound,
  setNoImageFound,
  handleCurrentProductImageUpdate,
  setCurrentProductId,
  setCurrentProductName,
  setCurrentProductImages,
  currentProductId,
  currentProductName,
  prod,
  removeProduct,
  updateProductInfo,
  currentProductImages,
  downloadingProductImage,
  productIndex,
  props,
  setShowAdvancedOptionsForCurrentProduct,
  showAdvancedOptionsForCurrentProduct,
}) {
  const { token } = useOutletContext();
  return (
    <ProductInput
      onChange={() => {
        setCurrentProductName(prod.name);
      }}
      onClick={(e) => {
        if (currentProductId != productIndex || currentProductName != prod.name)
          setCurrentProductImages([]);
        setCurrentProductId(productIndex);
        setCurrentProductName(prod.name);
      }}
      data-product-id={productIndex}
      className="product"
      key={`product-${productIndex}`}
    >
      {/*User should not be able to remove product when a grid was choose because it brokes the grid */}
      {props.grid ? (
        <span className="not-deleteable-message">
          Produto não pode ser removido quando grade de produtos está ativada
        </span>
      ) : removeProduct ? (
        <div className="icon-wrapper flex align" onClick={removeProduct}>
          <span>remover</span>
          <IonIcon icon={trashOutline} className="icon" />{" "}
        </div>
      ) : (
        ""
      )}

      <Input
        value={prod.name}
        className="name"
        type="text"
        placeholder="nome do produto"
        onInput={(e) => {
          updateProductInfo(productIndex, "name", e.target.value);
          setCurrentProductName(e.target.value);
          setCurrentProductId(productIndex);
        }}
      />
      <LabelInputWrapper>
        <Input
          value={prod.promotionPrice}
          data="valor"
          placeholder="R$00,00"
          type="text"
          className="price"
          onInput={(e) => {
            const parsedPrice = parsePrice(e.target.value);
            if (parsedPrice)
              updateProductInfo(productIndex, "promotionPrice", parsedPrice);
          }}
        />
      </LabelInputWrapper>
      <LabelInputWrapper>
        <Selector
          data="unidade"
          className="unidade"
          options={UNITYS}
          callback={(v) => {
            updateProductInfo(productIndex, "measurement", v);
          }}
          txt="valor por"
          selected={prod.measurement}
        />
      </LabelInputWrapper>
      {setShowAdvancedOptionsForCurrentProduct && (
        <AdvancedOptionsButton
          className="flex align min-gap justify"
          onClick={() => {
            setShowAdvancedOptionsForCurrentProduct(
              !showAdvancedOptionsForCurrentProduct
            );
          }}
        >
          <span>Opções Avançadas </span>
          <IonIcon icon={settingsOutline} className="icon" />
        </AdvancedOptionsButton>
      )}
      <BoxImage className="box" imagesCount={currentProductImages.length}>
        {currentProductId === productIndex ? (
          currentProductImages !== undefined &&
          currentProductImages.length > 0 ? (
            downloadingProductImage ? (
              <Loading color="#fff" $NoBgColor txt={"Baixando imagem..."} />
            ) : (
              currentProductImages.map((image, id) => (
                <Image
                  onClick={() => {
                    handleCurrentProductImageUpdate(image);
                  }}
                  url={`${image.displayUrl}`}
                  height="9rem"
                  hover={true}
                  key={`image-${id}`}
                  backgroundSize="contain"
                />
              ))
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </BoxImage>
      <NoImagesMessage>
        {wasNoImageFound &&
          `Nenhuma imagem foi encontrada para o produto "${currentProductName}"`}
      </NoImagesMessage>

      <div className="buttons flex direction-column min-gap">
        <Button
          color={"#fff"}
          $fullWidth
          size="1.4"
          $NoBgColor
          text={"Buscar imagens"}
          action={async () => {
            setNoImageFound(false);
            console.log(currentProductName);
            if (
              !currentProductName ||
              currentProductName.length < 3 ||
              currentProductName.toLowerCase().includes("nome do produto")
            )
              return;
            const imgs = await searchProductImages(
              currentProductName,
              token,
              currentProductImages.length + 1
            );
            setCurrentProductImages(imgs);
            if (imgs.length <= 0) setNoImageFound(true);
          }}
        />
        <UploadButton
          $fullWidth
          size="1.4"
          text={"Carregar da galeria"}
          perform={(url) => {
            updateProductInfo(currentProductId, "displayUrl", url);
          }}
        />
      </div>
    </ProductInput>
  );
}

export const parsePrice = (price) => {
  //strip comma
  price = price.split(",").join("");
  console.log(price, typeof price);
  const digitRegex = RegExp("^\\d+$");
  const zeroRegex = RegExp("^0+$");
  if (!price || price.length == 0 || !digitRegex.test(price)) return;
  const digitCount = price.length;
  let parsedPrice = ``;
  if (digitCount >= 3) {
    const cents = price.slice(digitCount - 2);
    let real = price.slice(0, digitCount - 2);
    if (zeroRegex.test(real)) {
      real = "0";
    } else if (real[0] === "0") {
      real = real.slice(1);
    }
    parsedPrice = `${real},${cents}`;
  } else if (digitCount == 2) {
    parsedPrice = `0,${price}`;
  } else if (digitCount == 1) {
    parsedPrice = `0,0${price}`;
  }

  return parsedPrice;
};

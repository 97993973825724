import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import Loading from "../sharable/Loading";
import { useSearchParams } from "react-router-dom";
import Logo from "../sharable/Logo";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  gap: 3rem;
  padding-bottom: 3rem;
  position: relative;
  width: 100vw;
  overflow-y: scroll;

  header {
    padding: 2rem 4.2rem;
  }
  nav {
    font-size: 1.6rem;
    width: 100%;
    justify-content: center;

    gap: 3rem;
    span {
      cursor: pointer;
      transition: color ease-in-out 200ms;
      &:hover {
        color: #d97706;
      }
    }
  }
  .nav {
    align-self: center !important;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.4rem;
`;

const Description = styled.p`
  text-align: center;
  font-size: 1.4rem;
  span {
    color: #d97706;
  }
`;
const API_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripe = loadStripe(API_KEY);

//COMPONENT VERIFIED 31/05;
export default function SubscriptionView() {
  const { token, activeAccount } = useOutletContext();
  const [clientSecret, setClientSecret] = useState();
  let [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    //IF USER ACCOUNT IS ACTIVE, GET A PORTAL SESSION AND REDIRECT USER
    //ELSE GET A CHECKOUT SESSION AND LOAD

    const handleUserCheckoutPortalSession = async () => {
      try {
        if (!token) return;
        const lookUpKey = params.get("lookup_key");
        //CONFIRMS LOOKUP IS VALID
        //const t = plansKeys.filter((k) => k === lookUpKey)[0];

        if (activeAccount) loadPortalSession();
        else loadCheckoutSession(lookUpKey);
      } catch (err) {
        navigate("/planos");
      }
    };
    handleUserCheckoutPortalSession();
  }, [activeAccount, token]);

  const loadPortalSession = async () => {
    const url = `${BASE_URL}subscription/portal-session`;
    const pres = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    const redirect = await pres.json();
    window.location.replace(redirect.url);
  };
  const loadCheckoutSession = async (lookUpKey) => {
    const url = `${BASE_URL}subscription/checkout-session?lookup_key=${lookUpKey}`;
    const res = await fetch(url, {
      method: "POST",
      headers: { Authorization: token },
    });
    if (res.ok) {
      const s = await res.json();
      setClientSecret(s.client_secret);
    } else navigate("/planos");
  };
  return (
    <Container className="flex direction-column">
      <header className="flex align">
        <Logo showGoBackButton={true} path={"/planos"} />
        <nav className="flex">
          <span onClick={() => navigate("/planos")}>Ver planos</span>
          <span onClick={() => navigate("/encartes")}>Página inicial</span>
        </nav>
      </header>
      {clientSecret && stripe ? (
        <>
          <div>
            <Title>Assinatura</Title>
            <Description>
              Com o <span>OfertasExpress</span> você consegue expor suas ofertas
              de forma rápida e atrair mais clientes para o seu negócio
            </Description>
          </div>
          <div id="checkout">
            <EmbeddedCheckoutProvider
              stripe={stripe}
              options={{
                clientSecret: clientSecret,
                onComplete: () => navigate("/formulario"),
              }}
            >
              <EmbeddedCheckout className="checkout" />
            </EmbeddedCheckoutProvider>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

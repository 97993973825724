import ProductObj from "../../../OBJRep/Product";
import ProductStyles from "../../../OBJRep/ProductStyles";

//DEFAULT ENCARTE MODEL
export const DEFAULT_ENCARTE_MODEL =
  "/resources/files/location/super-ofertas.png";
export const DEFAULT_PRICE_STYLE_SERVER_LOCATION =
  "/resources/files/location/price-model-default.png/";

export const DEFAULT_PRICE_PUBLIC_LOCATION = `images/price _default.png`;
//SEARCH RESOURCES
export const PRICE_STYLE_SEARCH_KEYWORD = "price_style";
export const ENCARTE_MODEL_SEARCH_KEYWORD = "models";

//FORMATS
export const FEED_INSTAGRAM = "feed Instagram";
export const STORIES = "stories";
export const PANFLETO = "panfleto";
export const PAISAGEM = "paisagem";
//MAX PRODUCTS FOR EACH FORMAT
export const MAX_PRODUCTS_FEED_FORMAT = 8;
export const MAX_PRODUCTS_STORIES_FORMAT = 12;
export const MAX_PRODUCTS = 16;

//DIMENSIONS
export const ENCARTE_HEADER_PX_STORIES = 600;
export const ENCARTE_FOOTER_PX_STORIES = 220;

export const ENCARTE_HEADER_PX_FEED = 380;
export const ENCARTE_FOOTER_PX_FEED = 120;

export const FEED_INSTAGRAM_DIM = {
  width: 1080,
  height: 1080,
  header: 380,
  footer: 60,
};

export const STORIES_DIM = {
  width: 1080,
  height: 1920,
  header: 600,
  footer: 220,
};

export const PANFLETO_DIM = {
  width: 1500,
  height: 2118,
  header: 530,
  footer: 220,
};

export const PAISAGEM_DIM = {
  width: 1600,
  height: 1131,
  header: 746,
  footer: 100,
};

//FONTS
export const ARIAL_FONT = "arial";
export const TIMES_NEW_ROMAN_FONT = "times new roman";
export const ROBOTO_FONT = "roboto";
export const PROTEST_STRIKE_FONT = "Protest Strike";
export const UBUNTU_FONT = "Ubuntu";
export const INTER_FONT = "Inter";
export const BLINKER_FONT = "Blinker";
export const ALEO_FONT = "Aleo";
export const OSWALD_FONT = "Oswald";
export const POPPINS_FONT = "Poppins";
export const STAATLICHES_FONT = "Staatliches";
export const TEKO_FONT = "Teko";

//UNITY MEASUREMENT
export const UNITYS = [
  "UNID",
  "QUILO",
  "CAIXA",
  "PACOTE",
  "METRO",
  "DUZIA",
  "CARTELA",
  "GRAMA",
  "FARDO",
];

//PRICES

//export const DEFAULT_PRICE_STYLE = "TAG";
export const ROUNDED_PRICE_STYLE = "ROUNDED";
export const ROUGHLY_ROUNDED_PRICE_STYLE = "MINIMALLY_ROUNDED";

//FONT SIZES
export const FONT_SIZES = [
  "18",
  "22",
  "26",
  "30",
  "34",
  "38",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];

//LOGO SIZES

export const LOGO_SIZE_SMALL = 200;
export const LOGO_SIZE_MEDIUM = 350;
export const LOGO_SIZE_LARGE = 450;

//LOGO STYLE
export const ROUND_LOGO_WITH_BORDER = "ROUND_WITH_BORDER";
export const ROUND_LOGO_WITHOUT_BORDER = "ROUND_WITHOUT_BORDER";
export const SQUARE_LOGO_WITH_BORDER = "SQUARE_WITH_BORDER";
export const SQUARE_LOGO_WITHOUT_BORDER = "SQUARE_WITHOUT_BORDER";

//CANVAS
export const CANVAS_TARGET_CLASSNAME = "CANVAS";
export const CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME = "CANVAS_WRAPPER";
export const CANVAS_VIWER_TARGET_CLASSNAME = "CANVAS_VIEWER";

//PRODUCT_LAYOUT
export const NAME_ABOVE_PRODUCT_LAYOUT = "NAME_ABOVE";
export const NAME_BENEATH_PRODUCT_LAYOUT = "NAME_BENEATH";
export const TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT = "TWO_COLUMNS_NAME_LEFT";
export const NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT =
  "NAME_AND_PRICE_SIDE_BY_SIDE";

//PROMOTIONS
export const FROM_X_TO_Y_PROMOTION = "FROM_X_TO_Y";
export const TAKE_X_PAY_Y_PROMOTION = "TAKE_X_PAY_Y";
export const SIMPLE_PROMOTION = "SIMPLE";

export const TXT_COLORS = [
  "#E6E6E6",
  "#f8fafc",
  "#0C0C0C",
  "#292524",
  "#696969",
  "#404040",
];
export const COLORS = [
  //"#ea580c",
  "#f59e0b",
  "#991b1b",
  "#255555",
  "#854d0e",
  "#9a3412",
  "#115e59",
  "#155e75",
  "#075985",
  "#1e40af",
  "#3730a3",
  "#5b21b6",
  "#9d174d",
  "#9f1239",
  "#86198f",
  "#f8fafc",
  "#0C0C0C",
  "#E6E6E6",
  "#404040",
  "#292524",
  "#696969",
];

export const FONT_FAMILIES = [
  PROTEST_STRIKE_FONT,
  ALEO_FONT,
  BLINKER_FONT,
  OSWALD_FONT,
  POPPINS_FONT,
  ROBOTO_FONT,
  STAATLICHES_FONT,
  TEKO_FONT,
  UBUNTU_FONT,
];

export const ENCARTE_FORMATS = [FEED_INSTAGRAM, STORIES];

/**********************PRODUCT EXAMPLES***********************/

//REUSABLE STYLES
const PRODUCT_STYLES = ProductStyles();
PRODUCT_STYLES.fontSize = "72";

export const PRODUCT_WITH_FROM_X_TO_Y_PROMOTION = ProductObj(
  0,
  "Coca Cola",
  "7,99",
  "",
  "",
  UNITYS[0],
  PRODUCT_STYLES,
  FROM_X_TO_Y_PROMOTION,
  undefined,
  undefined,
  "9,99"
);

import { SIMPLE_PROMOTION } from "../components/WorkSpace/CanvasViewer/EncarteConstants";
import ProductStyles from "./ProductStyles";

export default function ProductObj(
  id = 0,
  name = `Nome do produto ${++id}`,
  promotionPrice = "00,00",
  imageURL = "",
  imageURLTmp = "",
  measurement = "unid",
  styles = ProductStyles(),
  promotionType = SIMPLE_PROMOTION,
  takeX,
  payY,
  normalSellingPrice
) {
  return {
    id: id,
    name: name,
    promotionPrice: promotionPrice,
    imageURL: imageURL,
    measurement: measurement,
    imageURLTmp: imageURLTmp,
    styles: styles,
    promotionType: promotionType,
    takeX: takeX,
    payY: payY,
    normalSellingPrice: normalSellingPrice,
  };
}

import React, { useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  DEFAULT_PRICE_PUBLIC_LOCATION,
  FROM_X_TO_Y_PROMOTION,
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
  TAKE_X_PAY_Y_PROMOTION,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../WorkSpace/CanvasViewer/EncarteConstants";
import { PriceImage } from "../WorkSpace/ToolBarMenuViews/PricesMenuView";

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Measurement = styled.div`
  text-transform: uppercase;
  position: relative;
  //font-weight: 600;

  text-align: right;
  ${({ color, fontSize, scale }) => {
    const fontScale = fontSize * scale;
    return css`
      font-size: ${fontScale * 2 * 0.3}px !important;
    `;
  }}
`;

const GeneralStyle = css`
  z-index: 3;
  //transform: translate(25%, 25%);
  line-height: 1;
  widht: 100%;
  .number-wrapper {
    font-weight: 800;
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .sign {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
  }

  .decimal-unity-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 1;
  }

  .decimal {
    letter-spacing: -1px;
  }

  ${({ styles, scale, position }) => {
    const fontScale = styles.fontSize * scale;
    const signBackgroundColor = styles.priceBgColor;
    const signTextColor = styles.priceTxtColor;
    const pricePosition = position ? position : "absolute";
    return css`
      font-size: ${fontScale * 2.3}px;
      color: ${styles.txtColor} !important;
      position: ${pricePosition};
      .price-wrapper {
        background-color: ${styles.priceBgColor};
        color: ${styles.priceTxtColor};
      }
      .decimal {
        font-size: ${fontScale * 2 * 0.7}px;
      }

      .sign {
        font-size: ${fontScale * 2 * 0.36}px;
        background-color: ${signBackgroundColor};
        color: ${signTextColor};
      }
    `;
  }}
`;
const PromoPriceWrapper = styled.div`
  width: 100%;
  position: relative;
  ${({ promotion, styles }) => {
    let stylesCss = "";

    //CHECKING PRODUCT LAYOUT TO SET WETHER PROMOTION IS PLACED ASIDE PRICE OR ABOVE PRICE
    if (
      styles.productLayout === NAME_ABOVE_PRODUCT_LAYOUT ||
      styles.productLayout == NAME_BENEATH_PRODUCT_LAYOUT
    ) {
      stylesCss = css`
        flex-direction: row;
        justify-content: center;
        align-items: center;
        //gap: 4%;
      `;
    } else {
      stylesCss = css`
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `;
    }
    return stylesCss;
  }}
`;

const TakeXPayYPromo = styled.span`
  height: min-content;
  line-height: 1.2;
  padding: 0.5px 4px;
  text-transform: uppercase;
  width: 100%;
  align-self: end;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ scale, styles }) => {
    const fontScale = styles.fontSize * scale;
    return css`
      font-size: ${fontScale * 2 * 0.4}px;
      .txt {
        text-align: center;
        border-radius: ${10 * scale}px;
        padding: ${scale * 2}px;
        color: ${styles.bgColor};
      }

      .txt.take-x {
        width: 70%;
        color: ${styles.txtColor};
        border: ${scale * 2}px solid ${styles.txtColor};
      }

      .txt.pay-y {
        width: 80%;
        background-color: ${styles.txtColor};
      }
      .number {
      }
    `;
  }}
  ${({ styles }) => css`
    ${(styles.productLayout === NAME_ABOVE_PRODUCT_LAYOUT ||
      styles.productLayout === NAME_BENEATH_PRODUCT_LAYOUT) &&
    `max-width: 50%;
      width: 50%;    
      position: relative;`}
    ${styles.productLayout === NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT &&
    ` position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);`}

    text-align: center;
  `}
`;
const FromXToYPromo = styled.span`
  ${({ scale, styles }) => {
    const fontScale = styles.fontSize * scale;
    return css`
      border-radius: ${16 * scale}px;
      text-align: right;
      .wrapper {
        display: flex;
        gap: ${scale * 5}px;
        align-items: end;
      }

      .price {
        font-size: ${fontScale * 2 * 0.7}px;
        position: relative;
        color: ${styles.txtColor};
        &::after {
          content: "";
          width: 100%;
          height: ${scale * 3}px;
          background-color: ${styles.txtColor};
          bottom: 50%;
          left: 0;
          transform: rotate(25deg);
          position: absolute;
        }
        &::before {
          content: "";
          width: 100%;
          height: ${scale * 3}px;
          background-color: ${styles.txtColor};
          bottom: 50%;
          left: 0;
          transform: rotate(-25deg);
          position: absolute;
        }
      }

      .de,
      .por {
        font-size: ${fontScale * 2 * 0.4}px;
        background-color: ${styles.txtColor};
        color: ${styles.bgColor};
        border-radius: ${8 * scale}px;
        padding: 0.5px 4px;
      }
      ${styles.productLayout == NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT &&
      `
        position: absolute;
        top:0;
        transform: translateY(-100%);
        `}

      ${styles.productLayout != TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT &&
      styles.productLayout != NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT &&
      `
        .wrapper {
          display: grid;
          grid-template-columns: repeat(2, min-content);
          grid-template-rows: repeat(2, min-content);
          justify-items: center;
          align-items: center;
          column-gap: ${scale * 12}px;
          margin-right: ${scale * 12}px;
          .por {
          grid-column: 2/3;
          grid-row: 2/3;
          }

          .de {
            grid-column: 1/2;
            grid-row: 1/2;
          }

          .price {
          grid-column: 2/3;
          grid-row: 1/2;
          }
        }
      `}
    `;
  }}
`;
export const PriceContentWrapper = styled.div`
  ${GeneralStyle}
  ${({ styles, length, imageUrl, scale }) => {
    return css`
      //min-width: ${Number(styles.fontSize) * 3}px;
      //min-height: ${Number(styles.fontSize) * 3}px;
      width: ${((Number(styles.fontSize) * 2.5) / 2) * length * scale}px;
      height: ${((Number(styles.fontSize) * 1.1) / 2) * length * scale}px;

      background-image: url("${imageUrl && imageUrl !== ""
        ? imageUrl
        : DEFAULT_PRICE_PUBLIC_LOCATION}");
    `;
  }}
    background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 2;

  .price-wrapper {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .sign {
    position: relative;
    background-color: transparent;
  }
`;
export default function Price({ product, unity, scale, styles, position }) {
  return (
    <Container className="price flex justify-center align CONTAINER">
      <PromoPriceWrapper promotion={undefined} styles={styles} className="flex">
        {product.promotionType == TAKE_X_PAY_Y_PROMOTION && (
          <TakeXPayYPromo
            styles={styles}
            scale={scale}
            className="take-x-pay-y-span "
          >
            <p className="txt take-x">
              Leve <span className="number"> {product.takeX}</span>
            </p>

            <p className="txt pay-y">
              {product.payY ? `pague` : "por"}{" "}
              {product.payY && <span className="number"> {product.payY}</span>}
            </p>
          </TakeXPayYPromo>
        )}
        {product.promotionType == FROM_X_TO_Y_PROMOTION && (
          <>
            <FromXToYPromo
              styles={styles}
              scale={scale}
              className="take-x-pay-y-span min-gap flex"
            >
              <p className="wrapper">
                <span className="de">De</span>
                <span className="price"> {product.normalSellingPrice} </span>
                <p className="por">por</p>
              </p>
            </FromXToYPromo>
          </>
        )}
        <PriceContentWrapper
          className="flex CONTENT WRAPPER"
          scale={scale}
          styles={styles}
          position={position}
          length={product.promotionPrice.length}
          imageUrl={styles.priceStyleTmp}
        >
          <PriceContent
            price={product.promotionPrice}
            styles={styles}
            unity={unity}
            scale={scale}
          />
        </PriceContentWrapper>
      </PromoPriceWrapper>
    </Container>
  );
}

const PriceContent = ({ price, styles, unity, scale }) => {
  return (
    <>
      {price && price.length > 0 && (
        <div className="price-wrapper WRAPPER DO PRICE CONTENT">
          <span className="sign">R$</span>
          <div className="number-wrapper">
            <span className="integer">{price && price.slice(0, -3)}</span>
            <div className="decimal-unity-wrapper">
              <span className="decimal">{price && price.slice(-3)}</span>
              <Measurement
                fontSize={styles.fontSize}
                color={styles.txtColor}
                scale={scale}
              >
                {unity}
              </Measurement>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

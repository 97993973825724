import React, { useState, useEffect } from "react";
import { MenuTitle, MenuDescription } from "./Toolbar";
import { IonIcon } from "@ionic/react";
import { addOutline, closeCircleOutline } from "ionicons/icons";
import styled, { css, keyframes } from "styled-components";
import { Selector } from "../../sharable/PickerAndSelector";
import { trashOutline } from "ionicons/icons";
import ProductObj from "../../../OBJRep/Product";
import { useOutletContext } from "react-router";
import Button from "../../sharable/ActionButton";
import {
  FROM_X_TO_Y_PROMOTION,
  SIMPLE_PROMOTION,
  TAKE_X_PAY_Y_PROMOTION,
} from "../CanvasViewer/EncarteConstants";
import { toDataURL } from "../../../services/Utils";
import { fadeInAnimation } from "../../sharable/Animations";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import { devices } from "../../../Devices";
import { ProductAdvancedFields } from "../../../OBJRep/ProductAdvancedFields";
import { ProductForm } from "../../sharable/ProductForm";
const takeXPayYOptions = ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 8.4rem);
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export const BoxImage = styled.div`
  span {
    color: #fff !important;
  }
  position: relative;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #fff;
  color: black;
  align-items: center;
  justify-content: center;
  height: min-content;
  width: 100%;
  .loading-currentProductImages {
    animation: ${rotating} 1s linear infinite;
    color: #d97706;
    grid-column: 1/-1;
    display: inline-block;
  }
  .button {
    grid-column: 1/-1;
  }
  ${({ imagesCount }) => {
    const gridColumns = imagesCount < 4 ? imagesCount : 4;
    return css`
      grid-template-columns: repeat(${gridColumns}, 1fr);
    `;
  }}
`;

const ProdutosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddProductButton = styled.button`
  all: unset;
  width: calc(100% - 3px);
  padding: 1.2rem 0;
  font-size: 1.4rem;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  gap: 0.8rem;
  border-radius: 5px;
  font-weight: bold;
  &:hover {
    background-color: #d97706;
    border-color: transparent;
    transition: background-color 300ms ease-in-out;
  }
  &.disable {
    position: relative;
    border: none;
    cursor: default;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      border: 1px solid #fff;
      top: 50%;
    }
  }

  &.disable:hover {
    background-color: transparent;
  }
`;

const FloatWindowStyles = css`
  width: 32%;
  padding: 2.4rem;
  height: 50rem;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  bottom: 0%;
  left: 1%;
  box-shadow: 0px 0px 50px 2px rgba(1, 1, 1, 0.1);
  border-radius: 10px;
  color: #444444;
  animation: ${fadeInAnimation} 0.2s linear;
  .icon-wrapper {
    align-items: center;
    align-self: end;
    cursor: pointer;
    span,
    .icon {
      transition: all ease-in-out 200ms;
    }
  }
  .icon {
    align-self: end;
    color: black;
    font-size: 1.8rem;
  }

  .icon-wrapper:hover {
    .icon,
    span {
      color: #d97706;
    }
  }

  span {
    font-size: 1.4rem;
    text-align: center;
  }

  .edit-product-span {
    ont-size: 1.6rem;
    margin-top: 3.2rem;
  }
  //Promoções header
  h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 3.6rem;
    font-weight: 600;
  }

  p {
    font-size: 1.2rem;
  }

  .promo-fields-list {
    margin-top: 2.4rem;
    gap: 1.4rem;
    width: 70%;
  }

  input {
    border: none;
    padding: 1.2rem 0 1.2rem 1rem;
    text-transform: capitalize;
    height: 4.2rem;
    background-color: #f5f5f5;
  }

  @media ${devices.tablet} {
    width: 90%;
    max-height: 100vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .invalid-input {
    background-color: #ff7f7f !important;
  }
  .protective-layer {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: -2.4rem;
    color: #ffffff;
  }

  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      .close-advanced-fields,
      .icon {
        color: #ffffff;
        z-index: 11;
      }
    `}
`;
const AdvancedOptionsFloatWindow = styled.div`
  ${FloatWindowStyles};
  .promotion-select-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const PromotionSelectStyles = styled.div`
  width: 100%;
  border: 1px solid #e4e4e4;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 2rem;
  transition: all ease-in-out 200ms;
  cursor: pointer;
  h2 {
    font-size: 2.6rem;
    line-height: 1.2;
    font-weight: 600;
    padding: 1.4rem 0;
  }

  padding: 0 2rem;
  &&:hover {
    border-color: #d97706;
  }
`;

const PromotionExample = styled.div`
  ${({ url }) => css`
    background-image: url(${url});
    width: 10rem;
    height: 100%;
    background-size: cover;
    justify-self: end;
  `}
`;
const FromXToYPromoFloatWindow = styled.div`
  ${FloatWindowStyles}
  height: 55rem;
  h1 {
    margin-bottom: 0;
  }
  h2 {
    font-size: 2.4rem;
  }
  button {
    margin-top: 2rem;
  }
`;

const TakeXPayYPromoFloatWindow = styled.div`
  ${FloatWindowStyles}
  height: 60rem;
  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-size: 2.4rem;
  }
  button {
    margin-top: 2rem;
  }
`;

//COMPONENT VERIFIED
export default function InputProducts({ props }) {
  /*
  TODO:SEE IF IS POSSIBLE TO CREATE A CURRENT PRODUCT OBJECT CACHE INSTEAD OF 
  HAVING ONLY SOME PROPERTIES LIKE ID AND NAME
  */
  const { isAccountPremium } = useOutletContext();
  const [currentProductImages, setCurrentProductImages] = useState([]);
  const [currentProductName, setCurrentProductName] = useState("");
  const [currentProductId, setCurrentProductId] = useState(0);
  const [downloadingProductImage, setDownloadingProductImage] = useState(false);
  const [wasNoImageFound, setNoImageFound] = useState(false);
  const [
    showAdvancedOptionsForCurrentProduct,
    setShowAdvancedOptionsForCurrentProduct,
  ] = useState(false);
  const [
    currentOpenedPromotionFloatWindow,
    setCurrentOpenedPromotionFloatWindow,
  ] = useState(undefined);
  /**
   * THIS STATE IS NEEDED BECAUSE IN ADVANCED SETTINGS, FIELDS DO NOT CHANGE
   * ON INPUT, AND THIS OBJECT HOLDS THE STATE UNTIL USER SAVES.
   */
  const [productAdvancedFields, setProductAdvancedFields] = useState(
    ProductAdvancedFields()
  );

  const { token } = useOutletContext();

  /**
   * POPULATES productAdvancedFields WITH THE CURRENT PRODUCT DATA
   */
  const lodsAdvancedFieldsData = useEffect(() => {
    if (props.encarteProducts.length > 0) {
      const currentProduct = props.encarteProducts[currentProductId];
      const currentProductAdvancedFields = ProductAdvancedFields(
        currentProduct.promotionType,
        currentProduct.takeX,
        currentProduct.payY,
        currentProduct.normalSellingPrice,
        currentProduct.promotionPrice
      );
      setProductAdvancedFields(currentProductAdvancedFields);
    }
  }, [currentProductId]);
  const handleCurrentProductImageUpdate = (image) => {
    if (!image || !props.encarteProducts) return;
    setDownloadingProductImage(true);
    const arr = props.encarteProducts;

    if (image.url.includes("/resources/files")) {
      arr[currentProductId].displayUrl = image.displayUrl;
      arr[currentProductId].imageURL = image.url;
      props.updateEncarteProducts([...arr]);
      setDownloadingProductImage(false);
    } else {
      toDataURL(image.url).then((displayUrl) => {
        if (displayUrl === "") {
          alert(
            "Não foi possível realizar o download dessa imagem, por favor, selecione outra."
          );
          setCurrentProductImages(
            currentProductImages.filter((i) => i.url !== image.url)
          );
        }
        arr[currentProductId].displayUrl = displayUrl;
        arr[currentProductId].imageURL = image.url;
        props.updateEncarteProducts([...arr]);
        setDownloadingProductImage(false);
      });
    }
  };

  const updateProductInfo = (id, info, value) => {
    const arr = [...props.encarteProducts];
    arr.forEach((p, i) => {
      if (i == id) {
        p[info] = value;
      }
    });
    props.updateEncarteProducts(arr);
  };

  const updateProductAdvancedFields = (info, value) => {
    const productAdvancedFieldsCopy = { ...productAdvancedFields };
    productAdvancedFieldsCopy[info] = value;
    setProductAdvancedFields(productAdvancedFieldsCopy);
  };

  const removeProductEl = (el) => {
    const id = Number(
      el.target.closest(".product").getAttribute("data-product-id")
    );
    const arr = props.encarteProducts.filter((p, i) => i != id);
    props.updateEncarteProducts(arr);
  };

  const createNewProduct = async () => {
    const arr = [...props.encarteProducts];
    const pid = arr.length;
    const product = ProductObj(pid);
    arr.push(product);
    props.updateEncarteProducts(arr);
  };

  return (
    <Container>
      <div>
        {showAdvancedOptionsForCurrentProduct && (
          <AdvancedOptionsFloatWindow
            isAccountPremium={isAccountPremium}
            className="flex direction-column"
          >
            {!isAccountPremium && (
              <PremiumContentProtectiveLayer className="protective-layer" />
            )}
            <div
              className="icon-wrapper flex min-gap align close-advanced-fields"
              onClick={() => {
                setShowAdvancedOptionsForCurrentProduct(
                  !showAdvancedOptionsForCurrentProduct
                );
                setProductAdvancedFields(ProductAdvancedFields());
              }}
            >
              {" "}
              <span>Fechar</span>
              <IonIcon icon={closeCircleOutline} className="icon" />
            </div>
            <span className="edit-product-span">
              Editando <strong>'{currentProductName}'</strong>
            </span>
            <div className="promotion-select-list">
              <h1>Promoções</h1>
              <div className="flex direction-column">
                {props.encarteProducts[currentProductId].promotionType ==
                  TAKE_X_PAY_Y_PROMOTION && (
                  <div
                    onClick={() => {
                      updateProductInfo(
                        currentProductId,
                        "promotionType",
                        SIMPLE_PROMOTION
                      );
                    }}
                    className="icon-wrapper min-gap flex align justify"
                  >
                    <span>Remover</span>
                    <IonIcon icon={trashOutline} className="icon" />
                  </div>
                )}
                <PromotionSelectStyles
                  onClick={() => {
                    setCurrentOpenedPromotionFloatWindow(
                      TAKE_X_PAY_Y_PROMOTION
                    );
                  }}
                >
                  <h2>
                    Pague X,<br></br> Leve y
                  </h2>
                  <PromotionExample url="images/promotion-take-x-pay-y-example.png" />
                </PromotionSelectStyles>
              </div>
              {props.encarteProducts[currentProductId].promotionType ==
                FROM_X_TO_Y_PROMOTION && (
                <div
                  className="icon-wrapper flex min-gap align close-advanced-fields"
                  onClick={() => {
                    updateProductInfo(
                      currentProductId,
                      "promotionType",
                      SIMPLE_PROMOTION
                    );
                  }}
                >
                  <span>Remover</span>
                  <IonIcon icon={trashOutline} className="icon" />
                </div>
              )}
              <PromotionSelectStyles
                onClick={() => {
                  setCurrentOpenedPromotionFloatWindow(FROM_X_TO_Y_PROMOTION);
                }}
              >
                <h2>
                  De X, <br></br> por Y
                </h2>
                <PromotionExample url="images/promotion-from-x-to-y-example.png" />
              </PromotionSelectStyles>
            </div>
          </AdvancedOptionsFloatWindow>
        )}
        {currentOpenedPromotionFloatWindow === TAKE_X_PAY_Y_PROMOTION ? (
          <TakeXPayYPromoFloatWindow className="flex direction-column">
            <div
              className="icon-wrapper flex min-gap align"
              onClick={() => {
                setCurrentOpenedPromotionFloatWindow(undefined);
              }}
            >
              {" "}
              <span>Fechar</span>
              <IonIcon icon={closeCircleOutline} className="icon" />
            </div>
            <span className="edit-product-span">
              Editando <strong>'{currentProductName}'</strong>
            </span>
            <h1>Leve X, Pague Y</h1>
            <p>
              Você pode montar sua promoção como “Leve 5, pague 4 por R$19,99 ou
              apenas “Leve 4 por R$19,99”.{" "}
            </p>
            <div className="promo-fields-list flex direction-column justify-left">
              <div className="flex direction-column">
                <h2>Leve X</h2>
                <Selector
                  txt={""}
                  callback={(option) => {
                    if (option === "-") option = undefined;
                    updateProductAdvancedFields("takeX", option);
                  }}
                  options={takeXPayYOptions}
                  selected={productAdvancedFields.takeX}
                />
              </div>
              <div className={`flex direction-column`}>
                <h2>Pague Y (Opcional)</h2>
                {isPayYBigger(productAdvancedFields) && (
                  <p>Pague Y não pode ser maior que Leve X</p>
                )}
                <Selector
                  txt={""}
                  callback={(option) => {
                    if (option === "-") option = undefined;
                    updateProductAdvancedFields("payY", option);
                  }}
                  options={takeXPayYOptions}
                  selected={productAdvancedFields.payY}
                  customClassName={
                    isPayYBigger(productAdvancedFields) && "invalid-input"
                  }
                />
              </div>

              <div className="flex direction-column">
                <h2>Valor promocional</h2>
                <input
                  onInput={(e) => {
                    const parsedPrice = parsePrice(e.target.value);
                    if (parsedPrice)
                      updateProductAdvancedFields(
                        "promotionPrice",
                        parsedPrice
                      );
                  }}
                  type="text"
                  placeholder="Ex: 19,99"
                  value={productAdvancedFields.promotionPrice}
                />
              </div>
            </div>
            {productAdvancedFields.takeX == null ||
              (!isPayYBigger(productAdvancedFields) && (
                <Button
                  color={"#fff"}
                  text={"Salvar"}
                  width={"35%"}
                  $fullWidth={true}
                  action={() => {
                    const takeX = productAdvancedFields.takeX;
                    const payY = productAdvancedFields.payY;
                    const promotionPrice = productAdvancedFields.promotionPrice;
                    if (!isPayYBigger(productAdvancedFields)) {
                      updateProductInfo(currentProductId, "takeX", takeX);
                      updateProductInfo(currentProductId, "payY", payY);
                      updateProductInfo(
                        currentProductId,
                        "promotionPrice",
                        promotionPrice
                      );
                      updateProductInfo(
                        currentProductId,
                        "promotionType",
                        TAKE_X_PAY_Y_PROMOTION
                      );
                    }
                  }}
                />
              ))}
          </TakeXPayYPromoFloatWindow>
        ) : currentOpenedPromotionFloatWindow === FROM_X_TO_Y_PROMOTION ? (
          <FromXToYPromoFloatWindow className="flex direction-column">
            <div
              className="icon-wrapper flex min-gap align"
              onClick={() => setCurrentOpenedPromotionFloatWindow(undefined)}
            >
              {" "}
              <span>Fechar</span>
              <IonIcon icon={closeCircleOutline} className="icon" />
            </div>
            <span className="edit-product-span">
              Editando <strong>'{currentProductName}'</strong>
            </span>
            <h1>De X, Por Y</h1>
            <p>Você pode montar sua promoção como “De R$19,99, por R$18,99”.</p>
            <div className="promo-fields-list flex direction-column justify-left">
              <div className="flex direction-column">
                <h2>Valor não promocional</h2>
                <input
                  onInput={(e) => {
                    const parsedPrice = parsePrice(e.target.value);
                    if (parsedPrice)
                      updateProductAdvancedFields(
                        "normalSellingPrice",
                        parsedPrice
                      );
                  }}
                  type="text"
                  placeholder="Ex: 19,99"
                  value={productAdvancedFields.normalSellingPrice}
                  className="normal-selling-price"
                />
              </div>

              <div className="flex direction-column">
                <h2>Valor Promocional</h2>
                <p>
                  {!isPromotionPriceCheaper(productAdvancedFields) &&
                    "Valor promocional não pode ser mais caro"}
                </p>
                <input
                  className={`promotion-price ${
                    !isPromotionPriceCheaper(productAdvancedFields) &&
                    "invalid-input"
                  }`}
                  onInput={(e) => {
                    const parsedPrice = parsePrice(e.target.value);
                    updateProductAdvancedFields("promotionPrice", parsedPrice);
                  }}
                  type="text"
                  placeholder="Ex: 18,99"
                  value={
                    productAdvancedFields.promotionPrice
                      ? productAdvancedFields.promotionPrice
                      : props.encarteProducts[currentProductId].promotionPrice
                  }
                />
              </div>
            </div>
            {isPromotionPriceCheaper(productAdvancedFields) && (
              <Button
                color={"#fff"}
                text={"Salvar"}
                width={"35%"}
                $fullWidth={true}
                action={() => {
                  const normalSellingPrice =
                    productAdvancedFields.normalSellingPrice;
                  const promotionPrice = productAdvancedFields.promotionPrice;
                  if (isPriceCheaper(promotionPrice, normalSellingPrice)) {
                    updateProductInfo(
                      currentProductId,
                      "promotionType",
                      FROM_X_TO_Y_PROMOTION
                    );
                    updateProductInfo(
                      currentProductId,
                      "normalSellingPrice",
                      normalSellingPrice
                    );
                    updateProductInfo(
                      currentProductId,
                      "promotionPrice",
                      promotionPrice
                    );
                    alert("Alteração salva!");
                  } else {
                  }
                }}
              />
            )}
          </FromXToYPromoFloatWindow>
        ) : (
          ""
        )}
      </div>
      {props.encarteProducts && (
        <>
          <MenuDescription>
            Dica: Adicione os produtos que irão chamar atenção dos seus clientes
            e fazer eles comprarem de você.
          </MenuDescription>
          <MenuTitle>Produtos</MenuTitle>
          {!props.grid && (
            <AddProductButton
              className={
                props.maxProducts <= props.encarteProducts.length
                  ? "disable"
                  : ""
              }
              onClick={() => {
                if (props.maxProducts <= props.encarteProducts.length) return;
                createNewProduct();
              }}
            >
              Adicionar produto <IonIcon icon={addOutline} size="large" />
            </AddProductButton>
          )}

          <ProdutosContainer>
            {props.encarteProducts.map((prod, i) => {
              return (
                <ProductForm
                  currentProductId={currentProductId}
                  currentProductImages={currentProductImages}
                  currentProductName={currentProductName}
                  downloadingProductImage={downloadingProductImage}
                  handleCurrentProductImageUpdate={
                    handleCurrentProductImageUpdate
                  }
                  removeProduct={removeProductEl}
                  prod={prod}
                  setCurrentProductId={setCurrentProductId}
                  setCurrentProductImages={setCurrentProductImages}
                  setNoImageFound={setNoImageFound}
                  setCurrentProductName={setCurrentProductName}
                  updateProductInfo={updateProductInfo}
                  wasNoImageFound={wasNoImageFound}
                  productIndex={i}
                  props={props}
                  setShowAdvancedOptionsForCurrentProduct={
                    setShowAdvancedOptionsForCurrentProduct
                  }
                  showAdvancedOptionsForCurrentProduct={
                    showAdvancedOptionsForCurrentProduct
                  }
                />
              );
            })}
          </ProdutosContainer>
        </>
      )}
    </Container>
  );
}

const isPayYBigger = (productAdvancedFields) => {
  return (
    productAdvancedFields.takeX &&
    productAdvancedFields.payY &&
    productAdvancedFields.payY > productAdvancedFields.takeX
  );
};

const isPromotionPriceCheaper = (productAdvancedFields) => {
  return (
    productAdvancedFields.normalSellingPrice &&
    productAdvancedFields.promotionPrice &&
    isPriceCheaper(
      productAdvancedFields.promotionPrice,
      productAdvancedFields.normalSellingPrice
    )
  );
};

export const parsePrice = (price) => {
  //strip comma
  price = price.split(",").join("");
  console.log(price, typeof price);
  const digitRegex = RegExp("^\\d+$");
  const zeroRegex = RegExp("^0+$");
  if (!price || price.length == 0 || !digitRegex.test(price)) return;
  const digitCount = price.length;
  let parsedPrice = ``;
  if (digitCount >= 3) {
    const cents = price.slice(digitCount - 2);
    let real = price.slice(0, digitCount - 2);
    if (zeroRegex.test(real)) {
      real = "0";
    } else if (real[0] === "0") {
      real = real.slice(1);
    }
    parsedPrice = `${real},${cents}`;
  } else if (digitCount == 2) {
    parsedPrice = `0,${price}`;
  } else if (digitCount == 1) {
    parsedPrice = `0,0${price}`;
  }

  return parsedPrice;
};

/*
FUNCTION TO CHECK IF A PRICE IS CHEAPER THE OTHER.
PRICE FORMAT ACCEPTED AS STRING "0,00".
*/
const isPriceCheaper = (priceOne, priceTwo) => {
  return priceStringToNumber(priceOne) < priceStringToNumber(priceTwo);
};

const priceStringToNumber = (price) => {
  return Number(price.replace(",", "."));
};

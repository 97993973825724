import styled, { css, keyframes } from "styled-components";

export const fadeInAnimation = keyframes`
    from {
        opacity: 0%;
    }

    to: {
        opacity: 100%;
    }
`